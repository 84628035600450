<template>
  <strategy-parameter-block
    icon="fa fa-tasks"
    header-color="hg-success"
    :title="`${$t('param_allocation')}`"
    :info="info"
    :options="allocationOptions"
    :show-advanced="showAdvanced"
    v-model="method"
  >
    <label>{{ $t('allocationmethodtxt') }}</label>
    <el-select v-model="methodAdvanced">
      <el-option v-for="(option, index) in advancedOptions"
                 :key="index"
                 :value="option.value"
                 :label="option.label"
      ></el-option>
    </el-select>
    <fg-numeric v-model="allocationValue"
                :min="methodAdvanced.min"
                :max="methodAdvanced.max"
                :step="methodAdvanced.step"
                :precision="methodAdvanced.precision"
                :label="$t('ratiotxt')"
    ></fg-numeric>
  </strategy-parameter-block>
</template>

<script>
import StrategyParameterBlock from "@/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterBlock";
import {AllocationOptions} from "@/models/parameters/AllocationOptions";
import {mapActions, mapState} from "vuex";
export default {
  components: {StrategyParameterBlock},
  computed: {
    ...mapState('strategy_params', ['allocation']),
    info: {
      get() {
        if(this.showAdvanced) {
          return this.allocationOptions.find(aloc => this.allocation.method === aloc.value).info +
            this.advancedOptions.find(aloc => this.allocation.advancedMethod === aloc.value).info;
        } else {
          return this.allocationOptions.find(aloc => this.allocation.method === aloc.value).info;
        }
      }
    },
    method: {
      set(value) {
        this.setCurrentMethod(value);
      },
      get() {
        return this.allocation.method;
      },
    },
    methodAdvanced: {
      set(value) {
        this.setCurrentMethodAdvanced(value);
      },
      get() {
        return this.allocation.advancedMethod;
      },
    },
    allocationValue: {
      set(value) {
        this.setCurrentMethodValue(value);
      },
      get() {
        return this.allocation.value;
      },
    },
    showAdvanced: {
      get() {
        return this.method === AllocationOptions.ADVANCED;
      }
    },
  },
  data() {
    return {
      allocationOptions: [
        {
          value: AllocationOptions.PROPORTIONAL,
          label: AllocationOptions.PROPORTIONAL.label(),
          info: AllocationOptions.PROPORTIONAL.info(),
        },
        {
          value: AllocationOptions.DOUBLE,
          label: AllocationOptions.DOUBLE.label(),
          info: AllocationOptions.DOUBLE.info(),
        },
        {
          value: AllocationOptions.HALF,
          label: AllocationOptions.HALF.label(),
          info: AllocationOptions.HALF.info(),
        },
        {
          value: AllocationOptions.SAME,
          label: AllocationOptions.SAME.label(),
          info: AllocationOptions.SAME.info(),
        },
        {
          value: AllocationOptions.ADVANCED,
          label: this.$t('advancedsettings'),
          info: '',
        },
      ],
      advancedOptions: [
        {
          value: AllocationOptions.ADVANCED.BAL_RATIO,
          label: AllocationOptions.ADVANCED.BAL_RATIO.label(),
          info: AllocationOptions.ADVANCED.BAL_RATIO.info(),
        },
        {
          value: AllocationOptions.ADVANCED.EQ_RATIO,
          label: AllocationOptions.ADVANCED.EQ_RATIO.label(),
          info: AllocationOptions.ADVANCED.EQ_RATIO.info(),
        },
        {
          value: AllocationOptions.ADVANCED.LOT_MULTIPLIER,
          label: AllocationOptions.ADVANCED.LOT_MULTIPLIER.label(),
          info: AllocationOptions.ADVANCED.LOT_MULTIPLIER.info(),
        },
        {
          value: AllocationOptions.ADVANCED.MONEY_RATIO,
          label: AllocationOptions.ADVANCED.MONEY_RATIO.label(),
          info: AllocationOptions.ADVANCED.MONEY_RATIO.info(),
        },
        {
          value: AllocationOptions.ADVANCED.FIXED,
          label: AllocationOptions.ADVANCED.FIXED.label(),
          info: AllocationOptions.ADVANCED.FIXED.info(),
        },
      ],
    }
  },
  methods: {
    ...mapActions('strategy_params', ['setCurrentMethod', 'setCurrentMethodAdvanced', 'setCurrentMethodValue']),
  },
}
</script>

<style scoped>

</style>
