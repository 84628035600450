import { render, staticRenderFns } from "./StrategyParameterAllocation.vue?vue&type=template&id=1f5c0c1a&scoped=true&"
import script from "./StrategyParameterAllocation.vue?vue&type=script&lang=js&"
export * from "./StrategyParameterAllocation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5c0c1a",
  null
  
)

export default component.exports